<template>
    <div class="my-accout">
        <div class="my-head">
            <h3 class="my-title">账户设置</h3>
        </div>
        <div class="my-body">
            <el-form ref="ruleForm" :model="ruleForm" class="form-page">
                <el-form-item>
                    <img class="form-img" :src="imageUrl || require('@a/img/history-img.png')">
                    <span slot="label" class="form-label-hastips">头像照片<i class="form-label-tips">720X720PX的图像最佳，大小请不要超过3M</i></span>
                    <el-upload
                        class="upload-wrapper"
                        action="#"
                        accept="image/png,image/jpeg,image/gif"
                        :show-file-list="false"
                        :on-change="onUploadChange"
                        :auto-upload="false"
                    >
                        <a href="javascript:;" class="btn-plain">上传</a>
                    </el-upload>
                </el-form-item>
                <el-form-item prop="username" :rules="[{ required: true, message: '请输入用户昵称', trigger: 'blur' }]">
                    <span slot="label" class="form-label-hastips">用户昵称<i class="form-label-tips">2~16个字符</i></span>
                    <el-input v-model="ruleForm.username" :maxlength="16" clearable @keyup.enter.native="onSubmit">
                        <a slot="append" href="javascript:;" class="btn-plain" @click="getUsernameTrue">查重</a>
                    </el-input>
                </el-form-item>
                <el-form-item prop="sex" label="性别">
                    <div class="radio-wrapper">
                        <el-radio-group v-model="ruleForm.sex">
                            <el-radio label="0">保密</el-radio>
                            <el-radio label="1">男</el-radio>
                            <el-radio label="2">女</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item prop="birthday">
                    <span slot="label" class="form-label-hastips">生日<i class="form-label-tips">填写生日有惊喜哦~</i></span>
                    <el-date-picker
                        v-model="ruleForm.birthday"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :default-value="new Date()"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                    />
                </el-form-item>
                <!-- <el-form-item prop="eee" label="手机号">
                    <el-input v-model="ruleForm.phone" :maxlength="16" disabled @keyup.enter.native="onSubmit">
                        <a slot="append" href="javascript:;" class="btn-plain">修改</a>
                    </el-input>
                </el-form-item> -->
                <el-form-item prop="fff" label="密码">
                    <el-input v-model="ruleForm.password" show-password :maxlength="16" disabled @keyup.enter.native="onSubmit">
                        <a slot="append" href="javascript:;" class="btn-plain" @click="$router.push({ path: '/password' })">修改</a>
                    </el-input>
                </el-form-item>
                <el-form-item prop="ggg" label="收货地址">
                    <el-input v-model="ruleForm.address" :maxlength="16" disabled @keyup.enter.native="onSubmit">
                        <router-link slot="append" to="/my/address" class="btn-plain">修改</router-link>
                    </el-input>
                </el-form-item>
                <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">提交</a>
            </el-form>
        </div>
    </div>
</template>
<script>
import { getUserAccount } from '@api'
import { editUserAccount, getAddressList, getUsernameTrue } from '@api/my'
export default {
    name: 'MyAccout',
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            ruleForm: {
                // files : '', // 头像
                username: '', // 用户昵称
                sex: '0', // 性别
                birthday: '', // 生日
                // phone: '',
                address: '',
                password: '******',
                address: '',
                imgFile: null
            },
            imageUrl: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            }
        }
    },
    watch: {
        userInfo: {
            handler(val) {
                console.log('val', val)
                this.updateForm(val)
            },
            deep: true
        }
    },
    created() {
        console.log('this.userInfo', this.userInfo)
        this.updateForm(this.userInfo)
    },
    methods: {
        updateForm(userInfo) {
            // 更新当前表单信息
            this.ruleForm = Object.assign({}, this.ruleForm, userInfo)
            this.ruleForm.birthday = userInfo.birthday === '0000-00-00' ? '' : userInfo.birthday
            this.imageUrl = this.ruleForm.headimgurl
        },
        // 上传头像
        onUploadChange(file) {
            console.log(file.raw.type, file)
            const isJPG = ['image/jpeg', 'image/png', 'image/gif'].includes(file.raw.type)
            const isLt2M = file.size / 1024 / 1024 < 3
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
                return
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 3MB!')
                return
            }
            this.ruleForm.imgFile = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
        },
        // 用户名查重
        async getUsernameTrue() {
            const res = await getUsernameTrue({ username: this.ruleForm.username })
            if (res.detail && res.detail.status) {
                const msg = ['用户名没有重复', '用户名重复了'][ res.detail.status - 1 ]
                this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'warning', message: msg, position: 'bottom-right' })
            }
        },
        onSubmit() {
            console.log(this.ruleForm.imgFile)
            const data = {
                frames: 1,
                files: this.ruleForm.imgFile,
                ...this.ruleForm
            }
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = await editUserAccount(data)
                    if (res && res.detail) {
                        this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                        // 此处需要刷新以更新头部用户信息(后续可使用vuex优化)
                        location.reload()
                    }
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    min-height: 1000px;
    position: relative;
    padding: 20px;
    background-color: $white;
}
.form-page {
    width: 400px;
}
.upload-wrapper {
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom;
    .btn-plain {
        width: 110px;
    }
}
.form-img {
    display: inline-block;
    vertical-align: bottom;
    width: 80px;
    height: 80px;
}
</style>
